<template>
  <div>
    <vuestic-widget>
      <form
        autocomplete="off"
        class="va-row"
        @submit.prevent="editarEmpresa"
        v-if="empresa"
      >
        <div
          class="vuestic-alert alert with-close alert-success flex md12"
          v-if="request.ok"
        >
          Os dados da empresa foram alterados.
        </div>

        <div
          class="vuestic-alert alert with-close alert-danger flex md12"
          v-if="request.fail"
        >
          Ocorreu algum erro ao tentar salvar suas alterações.
        </div>

        <div class="flex md6 xs12">
          <div class="form-group">
            <div class="input-group">
              <input
                id="nome"
                v-model="empresa.nome"
                required
              />

              <label
                class="control-label"
                for="nome"
              >
                Nome
              </label>
            </div>
          </div>

          <div class="form-group">
            <div class="input-group">
              <the-mask
                id="cnpj"
                :mask="['###.###.###-##', '##.###.###/####-##']"
                v-model="empresa.cnpj"
                required
              />
              <label
                class="control-label"
                for="cnpj"
              >
                CNPJ
              </label>
            </div>
          </div>
        </div>

        <div class="flex md6 xs12">
          <div class="form-group">
            <div class="input-group">
              <input
                id="cidade"
                v-model="empresa.cidade"
                required
              />

              <label
                class="control-label"
                for="cidade"
              >
                Cidade
              </label>
            </div>
          </div>

          <div class="form-group">
            <div class="input-group">
              <the-mask
                id="cep"
                :mask="['#####-###']"
                v-model="empresa.cep"
                required
              />

              <label
                class="control-label"
                for="cep"
              >
                CEP
              </label>
            </div>
          </div>
        </div>

        <button
          class="btn btn-pale btn-micro mr-2  ml-auto"
          @click.prevent="$router.push({name: 'lista-empresas'})"
        >
          Voltar </button>
        <button
          type="submit"
          class="btn btn-primary btn-micro mr-2"
        >Confirmar</button>
      </form>
    </vuestic-widget>

    <tableInWidget
      :button="true"
      identificador="areas"
      :listaDeIds="empresa.areas"
      headerText="Áreas"
      nome="nome"
      label="NOME"
      :objetos="areas"
      v-on:indexRemovido="removeElemento"
      v-on:adicionarItem="$refs.modal.open()"
    />
    <tableInWidget
      :button="true"
      identificador="indicador"
      :listaDeIds="empresa.indicador"
      headerText="Indicadores"
      nome="descricao"
      label="NOME"
      :objetos="indicadores"
      v-on:indexRemovido="removeElemento"
      v-on:adicionarItem="$refs.modalIndicador.open()"
    />

    <tableInWidget
      :button="true"
      identificador="ferramenta"
      :listaDeIds="empresa.ferramenta"
      headerText="Ferramenta"
      nome="ferramenta"
      label="NOME"
      :objetos="ferramentas"
      v-on:indexRemovido="removeElemento"
      v-on:adicionarItem="$refs.modalFerramenta.open()"
    />

    <!-- Add Modelo -->
    <tableInWidget
      :button="true"
      identificador="modelo"
      :listaDeIds="[empresa.modelo]"
      headerText="Relato"
      nome="nome"
      label="NOME"
      :objetos="modelos"
      v-on:indexRemovido="editarModeloDaEmpresa(null)"
      v-on:adicionarItem="abriModalModelo()"
    />

    <!-- ADD AREAS -->
    <modal
      v-bind:small="true"
      v-bind:force="true"
      ref="modal"
      :cancelClass="'none'"
      v-on:ok="addArea"
    >
      <div slot="title">Adicionar uma área</div>
      <div>
        <form autocomplete="off">
          <multiselect
            v-model="area"
            placeholder="Áreas"
            label="nome"
            selectLabel="Selecione uma área"
            track-by="id"
            :options="areasNaovinculadas"
          >
            <span slot="noResult">Área não encontrada.</span>
          </multiselect>

        </form>
      </div>
    </modal>
    <!--ADD FERRAMENTAS-->
    <modal
      v-bind:small="true"
      v-bind:force="true"
      ref="modalFerramenta"
      :cancelClass="'none'"
      v-on:ok="addFerramenta"
    >
      <div slot="title">Adicionar uma ferramenta</div>
      <div>
        <form autocomplete="off">
          <multiselect
            v-model="ferramenta"
            placeholder="Ferramenta"
            label="ferramenta"
            selectLabel="Selecione uma ferramenta"
            track-by="id"
            :options="ferramentasNaovinculadas"
          >
            <span slot="noResult">Ferramenta não encontrada.</span>
          </multiselect>

        </form>
      </div>
    </modal>

    <!-- ADD INDICADOR -->
    <modal
      v-bind:small="true"
      v-bind:force="true"
      ref="modalIndicador"
      :cancelClass="'none'"
      v-on:ok="addIndicador"
    >
      <div slot="title">Adicionar um indicador</div>
      <div>
        <form autocomplete="off">
          <multiselect
            v-model="indicador"
            placeholder="Indicadores"
            label="descricao"
            selectLabel="Selecione um indicador"
            track-by="id"
            :options="indicadoresNaovinculadas"
          >
            <span slot="noResult">Indicador não encontrado.</span>
          </multiselect>

        </form>
      </div>
    </modal>
     <modal
      v-bind:small="true"
      v-bind:force="true"
      ref="modalModelos"
      :cancelClass="'none'"
      v-on:ok="editarModeloDaEmpresa(modelo.id)"
    >
      <div slot="title">Adicionar um Relato</div>
      <div>
        <form autocomplete="off">
          <multiselect
            v-model="modelo"
            placeholder="Relato"
            label="nome"
            selectLabel="Selecione um relato"
            track-by="id"
            :options="modelos"
          >
            <span slot="noResult">Relato não encontrado.</span>
          </multiselect>

        </form>
      </div>
    </modal>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import Modal from '@/vuestic-theme/vuestic-components/vuestic-modal/VuesticModal.vue';
import tableInWidget from '@/components/my-components/widget/tableInWidget.vue';

export default {
  name: 'editar-empresa',
  components: {
    Modal, Multiselect, tableInWidget,
  },
  data() {
    return {
      area: false,
      ferramenta: [],
      areas: [],
      indicador: false,
      indicadores: [],
      empresa: [],
      ferramentas: [],
      modelos: [],
      modelo: false,
      request: {
        ok: false,
        fail: false,
      },
    };
  },
  computed: {
    id() {
      return this.$router.history.current.params.id;
    },
    ferramentasNaovinculadas() {
      return this.ferramentas.filter(f => this.empresa.ferramenta.indexOf(f.id) === -1);
    },
    areasNaovinculadas() {
      return this.areas.filter(f => this.empresa.areas.indexOf(f.id) === -1);
    },
    indicadoresNaovinculadas() {
      return this.indicadores.filter(f => this.empresa.indicador.indexOf(f.id) === -1);
    },

  },

  mounted() {
    this.getEmpresa();
    this.getAreas();
    this.getFerramentas();
    this.getIndicadores();
    this.getModelos();
  },

  methods: {
    addArea() {
      this.editarEmpresa();
      this.empresa.areas.push(this.area.id);
      this.$refs.modal.close();
      this.area = [];
    },
    addFerramenta() {
      this.empresa.ferramenta.push(this.ferramenta.id);
      this.editarEmpresa();
      this.$refs.modalFerramenta.close();
      this.ferramenta = [];
    },

    removeElemento(evento) {
      this.empresa[evento.identificador].splice(evento.index, 1);
      this.editarEmpresa();
    },
    message(type, title) {
      this.$swal({
        toast: true,
        position: 'top-end',
        showCancelButton: false,
        type,
        title,
        showConfirmButton: false,
        timer: 2000,
      });
    },
    editarEmpresa() {
      this.$axios.put(`/api/empresa/${this.id}.json`, this.empresa)
        .then((res) => {
          this.empresa = res.data;
          this.message('success', 'Empresa Editada com Sucesso!!!');
          this.request.ok = true;
          this.request.fail = false;
        })
        .catch(() => {
          this.message('error', 'Erro ao Editar Empresa!!!');
          this.request.ok = false;
          this.request.fail = true;
        });
    },

    getIndicadores() {
      this.$axios.get('/api/indicador/')
        .then((res) => {
          this.indicadores = res.data;
        });
    },
    addIndicador() {
      this.editarEmpresa();
      this.empresa.indicador.push(this.indicador.id);
      this.$refs.modalIndicador.close();
      this.indicador = [];
    },

    getEmpresa() {
      this.$axios.get(`/api/empresa/${this.id}`)
        .then((res) => {
          this.empresa = res.data;
        })
        .catch(() => {
          // eslint-disable-next-line no-alert
          alert('Empresa não encontrada.');
          this.$router.push('/');
        });
    },

    getAreas() {
      this.$axios.get('/api/area/')
      // eslint-disable-next-line no-return-assign
        .then(res => this.areas = res.data);
    },
    getFerramentas() {
      this.$axios.get('/api/ferramenta').then((res) => { this.ferramentas = res.data; });
    },
    getModelos() {
      this.$axios.get('api/modelo/').then((res) => {
        this.modelos = res.data;
      });
    },
    editarModeloDaEmpresa(id) {
      this.empresa.modelo = id;
      this.editarEmpresa();
    },
    abriModalModelo() {
      this.$refs.modalModelos.open();
      this.modelo = null;
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
i {
  cursor: pointer;
}
</style>
